// Styling for the masthead
header.masthead {
  text-align: center;
  color: white;
  background-image: url("#{$header-image}");
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-position: 0 0;
  background-size: cover;
  .intro-text {
    padding-top: 150px;
    padding-bottom: 100px;
    .intro-heading {
      font-size: 3.1rem;
      text-shadow: 2px 2px black;
      font-weight: 700;
      line-height: 3.1rem;
      margin-bottom: 0;
      @include script-font;
      .heading-suffix {
        font-size: 2.2rem;
      }
    }
    .intro-subheading {
      font-size: 1.5rem;
      text-shadow: 2px 2px black;
      font-weight: 300;
      line-height: 2.2rem;
      margin-bottom: 2rem;
      @include heading-font;
    }
  }
}
@media(min-width:768px) {
  header.masthead {
    min-height: 15rem;
    .intro-text {
      padding-top: 300px;
      padding-bottom: 200px;
      .intro-heading {
        font-size: 4.7rem;
        line-height: 4.7rem;
      }
    }
    &.page {
      background-position-y: -11rem;
    }
  }
}
