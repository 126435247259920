// Global styling for this template
body {
  overflow-x: hidden;
  @include body-font;
}

p {
  line-height: 1.5;
}

a {
  color: $primary;
  &:hover {
    color: darken($primary, 10%);
  }
}

.text-primary {
  color: $primary !important;
}

.section-heading {
  font-size: 1.65rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
  @include heading-font;
}
.page-section {
  padding: 50px 0;
  h2.section-heading {
    margin-top: 0;
    margin-bottom: 15px;
  }
  h3.section-subheading {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 75px;
    text-transform: none;
    @include serif-font;
  }
}
@media(min-width:768px) {
  section {
    padding: 150px 0;
  }
}

// Highlight color customization
::-moz-selection {
  background: $primary;
  text-shadow: none;
}

::selection {
  background: $primary;
  text-shadow: none;
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}
#contact {
  .section-subheading.text-muted > a {
    color: #e9ecef;
  }
}
#pagecontainer {
  padding: 4.5rem 15px 2rem;
  h3 {
    font-size: 1.25rem;
  }
  h4 {
    font-size: 1rem;
  }
}
@media(min-width:768px) {
  #pagecontainer {
    padding-top: 2rem;
  }
}