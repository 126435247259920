// Styling for the navbar
#mainNav {
  background-color: $secondary;
  .navbar-toggler {
    font-size: 12px;
    right: 0;
    padding: 13px;
    text-transform: uppercase;
    color: white;
    border: 0;
    background-color: $primary;
    @include heading-font;
  }
  .navbar-brand {
    color: #14426f;
    text-transform: uppercase;
    //text-shadow: 2px 2px rgba(0, 0, 0, 0.1);
    @include script-font;
    svg {
      height: 40px;
    }
    &.active,
    &:active,
    &:focus,
    &:hover {
      color: darken($primary, 10%);
    }
    .law-title {
      padding-left: 15px;
      padding-top: 6px;
      &-name {
        color: #808285;
      }
    }
  }
  .navbar-nav {
    .nav-item {
      .nav-link {
        font-size: 90%;
        font-weight: 400;
        padding: 0.75em 0;
        letter-spacing: 1px;
        color: white;
        @include heading-font;
        &.active,
        &:hover {
          color: $primary;
        }
      }
    }
  }
}
.navbar-brand {
  display: flex;
}
@media(min-width:992px) {
  #mainNav {
    -webkit-transition: padding-top 0.3s, padding-bottom 0.3s;
    -moz-transition: padding-top 0.3s, padding-bottom 0.3s;
    transition: padding-top 0.3s, padding-bottom 0.3s;
    border: none;
    background-color: $secondary;
    .navbar-brand {
      font-size: 2.25em;
      -webkit-transition: all 0.3s;
      -moz-transition: all 0.3s;
      transition: all 0.3s;
      svg {
        height: 60px;
      }
    }
    svg {
      width: 50px;
    }
    .navbar-nav {
      .nav-item {
        .nav-link {
          padding: 1.1em 1em !important;
        }
      }
    }
    &.navbar-shrink {
      padding-top: 0;
      padding-bottom: 0;
      background-color: $secondary;
      .navbar-brand {
        font-size: 1.8em;
        padding: 12px 0;
      }
    }
  }
}
